import React from 'react';
import { Link, graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';

import Layout from '../components/fr';
import SEO from '../components/seo';

import history from '../images/family/historique.jpg';
import sylvain from '../images/family/sylvain_furlan.jpg';
import family1 from '../images/family/famille.jpg';
import family2 from '../images/family/famille2.jpg';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/team/**/*.fr.md" } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            facebook
            twitter
            instagram
            cover {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            avatar {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const Team = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout location={typeof window !== 'undefined' ? location : null}>
      <SEO
        title="Furlan - Portrait et histoire de la marque"
        description="Découvrez notre équipe, Sylvain Furlan et nos riders"
        pathname="/fr/team/"
        keywords={['snowboard', 'boards', 'polyvalence']}
      />
      <div className="teamPage">
        <img src={history} alt="Furlan Snowboards" />
        <blockquote className="blockquote-credit">
          <blockquote className="blockquote-credit">
            @actsnowboarding (David Tchag)
          </blockquote>
        </blockquote>
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-4 mt-lg-5">
              <p className="lead ml-md-4">
                Furlan a une personnalité unique qui incarne les idéaux de
                Sylvain, son fondateur. Le but de ce shaper passionné et audacieux
                est de créer de planches qui apportent autant de plaisir sur la
                piste qu’en poudreuse, tout en conservant un feeling freestyle.
                Pour cela Sylvain a créé il y a 10 ans ce shape atypique qui
                greffait de longues spatules à une planche freestyle. En décalage
                avec la tendance de l’époque, il était en réalité totalement
                précurseur ! 10 ans plus tard, il est plus que jamais d’actualité
                et Furlan a fait sa place sur le marché grâce à une 'patte'
                inimitable. Pour autant Sylvain ne s’arrête pas là et continue à
                innover avec des planches comme l’excellente Astero, au look
                futuriste.
              </p>
              <p className="ml-md-4"><strong>Act Snowboarding Magazine</strong></p>
            </div>
            <div className="col-md-7 offset-md-1 mt-4">
              <img src={sylvain} alt="Furlan Snowboards" className="sylvain" />
              <p className="mt-3 mr-lg-4">
                A gauche, mon premier "surf des neiges", cadeau de Noël quand j'avais 11 ans, en 1984. Ce cadeau a changé ma vie ! Mon voisin de 12 ans voulait le même, son père nous a aidé à en fabriquer une réplique. Ça a été ma première expérience de fabrication. On les ridait en moonboots avec des lanières pour tenir les pieds. A sa droite, la "blood and fire", la première planche que j'ai fabriquée.
              </p>
              <p className="mt-3 ml-md-4">
                C'est toujours mon concept aujourd'hui: redessiner des grandes spatules sur une board freestyle de manière à obtenir de la flottaison tout en conservant le comportement d'une petite board. Mon concept était tellement bon à rider sur la neige que j'ai décidé de devenir fabricant et de commercialiser mes boards ! Je voulais me différencier des boards artisanales déco bois alors j'ai décidé de leur mettre un topsheet flashy avec mon nom en logo, inspiré d'un vieux logo du chocolat Poulain !
              </p>
              <p className="mt-3ml-md-4">
                Donc, j'ai créé officiellement la marque Furlan Snowboards en 2008 après quelques années depuis 1998, en tant marque artisanale confidentielle. Mes boards sont toujours le fruit de mes rêves: un retour aux racines du snow, adapter les boards de poudre aux jeunes générations de riders en quête de fun. Aujourd'hui je crée les boards les plus polyvalentes possible, les plus fun. Et quel que soit leur programme initial, mes boards ont toujours un petit truc en plus pour la poudre !
              </p>
              <p className="mt-3 ml-md-4">
                Une anecdote, on nous dit souvent "c'est une marque Américaine non ?". C'est marrant d'entendre ça... en réalité la marque est Française, nous sommes basés en Isère, aux pieds de la station de ski les 7 Laux-Prapoutel, collés entre Chambéry et Grenoble. Mais on peut trouver de fortes ressemblances avec certains aspects des USA: nous vivons en montagne, au milieu des vaches, dans un paysage de western et c'est vraiment une chance.
              </p>
              <p className="ml-md-4">
                <strong>Sylvain Furlan</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            {posts.map(({ node: post }) => (
              <div className="col-6 col-md-4 px-1 px-md-4 py-md-3" key={post.id}>
                <Link to={post.frontmatter.path}>
                  <Img
                    fluid={post.frontmatter.avatar.childImageSharp.fluid}
                    alt="Furlan Snowboards"
                  />
                  <h4 className="mt-3">{post.frontmatter.title}</h4>
                </Link>
                {!post.frontmatter.facebook === '' && (
                  <a
                    href={post.frontmatter.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook mx-2" />
                  </a>
                )}
                {!post.frontmatter.twitter === '' && (
                  <a
                    href={post.frontmatter.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab  fa-twitter mx-2" />
                  </a>
                )}
                {!post.frontmatter.instagram === '' && (
                  <a
                    href={post.frontmatter.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab  fa-instagram  mx-2" />
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Team;
